/* Copyright 2022 Esri
*
* Licensed under the Apache License Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/

* {
    font-family: "Open Sans", sans-serif!important;
}

@keyframes swoopy {
	from {
		transform: scale(0);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;	
	}
}

.card-img-top {
    display: block;
}

.card-body {
    background-size: 0%;
}

@media (max-height: 700px), (max-width: 576px) {
    .card-img-top {
        display: none;
    }
    .card-body {
        background-size: cover
    }
}

.esri-popup {margin-bottom: 35px;}
.esri-popup__footer {display: none}
.esri-view-width-medium .esri-popup__main-container {width: auto;}
.esri-view-orientation-landscape .esri-popup__main-container {width: auto;}
.esri-view-width-less-than-medium .esri-popup__main-container {width: auto;}

.d-none {
    display: none
}