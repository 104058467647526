/* Copyright 2022 Esri
*
* Licensed under the Apache License Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*     http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/

@keyframes blinkingBorder{
    0%		{ border-color: green;}
    25%		{ border-color: lime;}
    50%		{ border-color: green;}
    75%		{ border-color: lime;}
    100%	{ border-color: green;}
}

#square, #axis-y-top, #axis-y-bottom, #axis-x-left, #axis-x-right {
    touch-action: none;
    pointer-events: none;
}

#square {
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    width: 40px;
    height: 40px;
    border-radius: 80px;
    background-color: rgba(255, 0, 0, 0.4);
    border: solid;
    border-width: 4px;
    border-color: black;
    clip-path: circle(50%);
  }

#square.blinking {
    animation: blinkingBorder 1s infinite;
}

#axis-y-top, #axis-y-bottom, #axis-x-left, #axis-x-right {
    background-color: black;
}

#axis-y-top, #axis-y-bottom {
    left: 50%;    
    margin-left: -1px;
    height: calc(50% - 20px);
    width: 2px;
}

#axis-y-top {
    top: 0px;
}

#axis-y-bottom {
    top: calc(50% + 20px);
}

#axis-x-left, #axis-x-right {
    top: 50%;
    height: 2px;
    margin-top: -1px;
    width: calc(50% - 20px);
}

#axis-x-left {
    left: 0px;
}

#axis-x-right {
    left: calc(50% + 20px);
}